import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import bp from '../assets/js/breakpoints';
import EyeLogo from './eye-logo';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin-bottom: 5vh;

  @media (${bp.min.xs}) {
    height: 80vh;
    margin-bottom: 15vh;
  }
`;

const Title = styled.h1`
  position: absolute;
  left: -1000rem;
  top: auto;
  width: 0.2rem;
  height: 0.2rem;
  overflow: hidden;
`;

const HeroWrapper = styled.svg`
  width: 100%;
  max-width: clamp(36rem, 40vw, 60rem);
  max-height: clamp(20rem, 55vh, 50rem);
  overflow: visible;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
`;

const Wordmark = styled.g`
  fill: var(--c-white);

  g {
    visibility: hidden;
  }
`;

const Hero = () => {
  const universal = useRef(null);
  const patterns = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay: 0.25,
      defaults: { ease: 'power3.inOut' },
    });

    tl.from(
      [universal.current, patterns.current],
      {
        y: 20,
        autoAlpha: 0,
        duration: 0.9,
        stagger: 0.1,
        ease: 'cubic.inOut',
      },
      '<+.5'
    );
  }, []);

  return (
    <Container>
      <Title>Universal Patterns</Title>

      <Logo>
        <EyeLogo isHomepage />

        <HeroWrapper viewBox="0 0 733.71 593.72">
          <Wordmark>
            <g ref={universal}>
              <path d="M250.11,314.5c7,0,11.77-4.78,11.77-11.76,0-6.58-4.79-11.36-11.77-11.36-7.17,0-12.16,4.78-12.16,11.36C238,309.72,242.94,314.5,250.11,314.5Z" />
              <path d="M87.31,419.56v17.73h85.31V436.1l-15-5.78V359.56C162.45,352.18,169,344.8,177,344.8c9.37,0,17.94,6,17.94,20.34l-.2,65.18L179.8,436.1v1.19h95.43V436.1l-15-5.78V332.64H225.8v1.2l14.94,5.58v90.9L227.51,436l-13.23-5.66v-66c0-24.12-12.35-34.29-29.89-33.89-4.59.2-15.56,8.17-27.72,23.92-1.79-21.33-6.37-24.12-11.55-24.12-8.38,0-18.14,14.55-27.91,25.12l.59,1,20.54-11v84.92L122.8,436l-14.16-8.24v-125H72v1.2l15.15,8.37.2-.2V415c-7.38,6.18-14.15,11-25.12,11-14.95,0-25.31-9.76-25.31-32.49v-90.7H0v1.2l15.35,8.37v86.51c0,27.91,14.75,40.86,38.87,40.86C59.2,439.68,73,431.71,87.31,419.56Z" />
              <path d="M313.5,442.08l42.86-108.24v-1.2H321.87v1.2c17.35,7.57,21.93,17.54,11.76,46.84l-12,32.9h-1.2l-28.3-74h.2l15.95-5.78v-1.2H269.64v1.2L312.1,442.08Z" />
              <path d="M399.86,439.68c6.18,0,17.94-8.57,31.3-20.92l-.8-1.2c-6.58,3.79-11.76,7-19.54,7-24.71,0-40.46-20.73-42.85-47.05h40.46c4.59,0,21.33-9.36,21.33-13.55-.6-19.74-9.37-33.89-32.09-33.89-9.37,0-45.85,12.76-45.85,57.81C351.82,421.55,373.15,439.68,399.86,439.68Zm-32.29-69.36c0-25.92,12.56-31.5,21.93-31.5,11.76,0,21.92,12.76,20.72,35.09H367.57Z" />
              <path d="M455.93,345.4v84.92L441,436.1v1.19h49.23V436.1l-14.95-5.78v-71c3.39-6.18,8.17-12.56,11.56-12.56,7,0,13.56,11.16,20.74,11.16,3.19,0,6.3-2.64,9-5.95a30,30,0,0,0-.87,7.15c0,14.75,10.36,22.72,24.11,30.29L552,396.63c16.55,8.77,25.12,14.95,25.12,24.12,0,8.17-6,12.76-14.15,12.76-13,0-40.27-10.37-50.63-46.45h-1.6l5.19,47c11.36.2,20.53,5.58,35.68,5.58,25.71,0,35.88-15.14,35.88-29.89,0-16.75-13.16-24.92-27.51-32.9l-12.36-7.77c-11.76-6.18-20.93-11.76-20.93-21.53,0-6.78,4.39-12,13.55-12,18,0,32.5,17.15,41.07,41.07h1.59V330.25h-1.19L575.53,337c-7-5.39-16.95-7.38-25.32-7.38-12,0-21.84,4.17-27.92,11.25a7.7,7.7,0,0,1-4.77,1.71c-7,0-17.14-12.36-22.52-12.36-2.59,0-14,14.15-20.73,24.52-1.8-21.73-6.38-24.52-11.56-24.52-8.37,0-18.14,14.55-27.91,25.12l.59,1Z" />
              <path d="M616.24,370.92c-3.19-19.74,3.79-31.5,17.35-31.7,14.35-.2,17.34,14,17.34,30.9l-16.15,9.37c-19.34,11.76-38.67,25.31-38.67,42.26,0,10.56,7.38,17.93,20.73,17.93,14.95,0,24.12-9,34.29-25.11,2,21.73,6.58,24.52,11.76,24.52,8.37,0,18.14-14.55,27.91-25.12l-.8-1-20.34,11v-9.57l1.6-49.23c.8-17.95-2.59-35.69-27.51-35.69-7,0-34.48,9.77-50.63,49.84l.6.79Zm34.29,38.67c-8,12-16.35,16.94-22.93,16.94-5.78,0-10.76-4.38-10.76-12.75,0-12.17,10.17-23.93,34.09-38.88Z" />
              <polygon points="718.96 430.32 718.76 430.32 718.76 285 717.56 285 681.88 294.37 681.88 295.57 699.02 296.76 699.02 430.52 684.47 436.1 684.47 437.29 733.71 437.29 733.71 436.1 718.96 430.32" />
            </g>
            <g ref={patterns}>
              <path d="M104.31,538.11c39.07,0,56-21.33,56-43.86,0-27.11-21.33-37.48-52.82-37.48H55.27V458l15.15,8.37v116l-14.75,7.77v1.2h51v-1.2l-15-7.77V538.11ZM91.75,460.36h12c22.92,0,34.88,12,34.88,36.48,0,27.31-12.16,37.68-37.07,37.68l-9.77.2Z" />
              <path d="M229.64,578V568.4l1.6-49.23c.79-17.94-2.59-35.68-27.51-35.68-7,0-34.49,9.77-50.64,49.83l.6.8L176.22,525c-3.19-19.74,3.79-31.49,17.34-31.69,14.35-.2,17.34,13.95,17.34,30.89l-16.14,9.37c-19.34,11.76-38.68,25.32-38.68,42.26,0,10.57,7.38,17.94,20.74,17.94,15,0,24.12-9,34.28-25.12,2,21.73,6.58,24.52,11.76,24.52,8.38,0,18.14-14.55,27.91-25.12l-.8-1ZM210.5,563.62c-8,12-16.34,16.94-22.92,16.94-5.78,0-10.76-4.38-10.76-12.76,0-12.15,10.16-23.91,34.08-38.86Z" />
              <path d="M338.28,580c-7.78,0-11.77-4.59-11.77-13.56l.2-75.35c10.57,1.6,19.54,2.79,25.52,2.79v-7.17H326.71l.2-25.92h-1l-17.54,25.92H273.69l.2-25.92h-1l-17.54,25.92H239.4v1.2l14.95,4.18.2,77.34c.2,16.35,7.18,24.32,19.14,24.32,4.78,0,16.34-7.77,26.91-19.74l-.8-1c-6.78,5.78-10.56,7-14.55,7-7.77,0-11.76-4.59-11.76-13.56l.2-75.35,33.69,3.31.2,75c.2,16.35,7.18,24.32,19.13,24.32,4.79,0,16.35-7.77,26.92-19.74l-.8-1C346.05,578.77,342.26,580,338.28,580Z" />
              <path d="M412.23,578.57c-24.72,0-40.46-20.73-42.86-47h40.47c4.58,0,21.33-9.37,21.33-13.56-.6-19.73-9.37-33.88-32.1-33.88-9.37,0-45.84,12.75-45.84,57.81,0,33.68,21.33,51.82,48,51.82,6.18,0,17.94-8.57,31.29-20.93l-.8-1.2C425.19,575.38,420,578.57,412.23,578.57ZM369,524.35c0-25.91,12.55-31.49,21.92-31.49,11.76,0,21.93,12.75,20.73,35.08H369Z" />
              <path d="M597.3,518.37c0-24.12-12.36-34.28-29.9-33.88-4.59.2-15.55,8.17-27.71,23.91-1.79-21.32-6.38-24.11-11.56-24.11-3.9,0-8.1,8.5-12.47,14.86a4.31,4.31,0,0,1-6.78.38c-5.06-5.65-10.14-15.24-13.44-15.24-2.59,0-13.95,14.15-20.73,24.51-1.79-21.72-6.38-24.51-11.56-24.51-8.38,0-18.14,14.55-27.91,25.11l.6,1,20.53-11v84.92l-14.95,5.78v1.2h49.24v-1.2l-15-5.78v-71c3.39-6.18,8.17-12.56,11.56-12.56,8.4,0,12.21,11.16,20.73,11.16,5,0,9.86-6.53,13.35-12v84.33l-15,5.78v1.2h49.24v-1.2l-14.95-5.78V513.59c4.78-7.38,11.36-14.75,19.33-14.75,9.37,0,17.94,6,17.94,20.33l-.2,65.18-15,5.78v1.2h49.24v-1.2l-14.75-5.78Z" />
              <path d="M660.54,530.93l-12.36-7.77c-11.76-6.18-20.93-11.77-20.93-21.53,0-6.78,4.39-12,13.56-12,17.94,0,32.49,17.14,41.06,41.06h1.6V484.29h-1.2l-6.18,6.77c-7-5.38-17-7.37-25.32-7.37-20.13,0-34.48,11.76-34.48,29.5,0,14.75,10.37,22.73,24.12,30.3l12.16,7.17c16.54,8.77,25.12,15,25.12,24.12,0,8.18-6,12.76-14.16,12.76-13,0-40.26-10.37-50.63-46.44h-1.6l5.19,47c11.36.2,20.53,5.58,35.68,5.58,25.72,0,35.88-15.15,35.88-29.9C688.05,547.08,674.9,538.91,660.54,530.93Z" />
            </g>
          </Wordmark>
        </HeroWrapper>
      </Logo>
    </Container>
  );
};

export default Hero;
