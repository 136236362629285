import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';
import { isBrowser } from '../assets/js/utilities';
import Arrow from './arrow';

const Flickity = isBrowser ? require('react-flickity-component') : 'div';

const Container = styled.div`
  position: relative;
`;

const Title = styled.h2`
  font-size: clamp(3.6rem, 7vw, 7.2rem);
  font-variation-settings: 'wght' 500;
  width: 100%;
  margin: 0 auto var(--space-s);

  @media (${bp.min.sm}) {
    margin: 0 auto var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    width: 83%;
    margin: 0 auto var(--space-xl);
  }
`;

const Slide = styled.div`
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  transition: transform 250ms var(--easeInOutCubic),
    opacity 350ms var(--easeInOutCubic);

  @media (${bp.min.sm_md}) {
    width: 83.2%;
  }

  &:after {
    content: '';
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    display: block;
  }

  &:not(.is-selected) {
    transform: scale(0.95);
    opacity: 0.2;
  }

  img {
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
  }

  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Caption = styled.div`
  width: 100%;
  margin: var(--space-xs) auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: var(--c-gray);

  @media (${bp.min.sm_md}) {
    margin: var(--space-m) auto;
    width: 83%;
  }

  .project {
    font-size: 2rem;
    line-height: 1.2;
    padding-right: var(--space-s);

    @media (${bp.min.xs}) {
      font-size: 2.4rem;
    }

    @media (${bp.min.sm}) {
      font-size: 3.2rem;
    }
  }

  .slide-count {
    font-family: var(--f-sans);
    font-size: 1.6rem;
    font-weight: var(--f-light);
    white-space: nowrap;
  }
`;

const Navigation = styled.div`
  width: 100%;
  position: absolute;
  top: 4vw;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
  display: none;

  @media (${bp.min.sm_md}) {
    display: block;
  }

  .previous,
  .next {
    position: absolute;
    height: 100%;
    width: 10%;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 250ms var(--easeInOutCubic);

    &:hover {
      transform: translateX(var(--offset)) scale(1.1);
    }

    &:focus {
      outline: none;
    }
  }

  .previous {
    --offset: calc(-1 * var(--space-xxxs));
    left: -4.8rem;
  }

  .next {
    --offset: var(--space-xxxs);
    right: -4.8rem;
  }
`;

const Slideshow = ({ data }) => {
  const { portfolio_title, projects } = data;
  const [caption, setCaption] = useState(null);
  const [slide, setSlide] = useState(null);
  const [flkty, setFlkty] = useState(null);
  const videos = useRef([]);

  const flktyOptions = {
    wrapAround: true,
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'center',
    selectedAttraction: 0.025,
    friction: 0.3,
  };

  useEffect(() => {
    if (!!flkty) {
      // Prevent body scrolling while swiping on touch devices
      const tapArea = flkty.element;
      let startX = 0;

      tapArea.ontouchstart = function (e) {
        startX = e.touches[0].clientX;
      };

      tapArea.ontouchmove = function (e) {
        if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
          e.preventDefault();
        }
      };

      // Update project name/slide count on slide update
      flkty.on('select', function () {
        setCaption(flkty.selectedElement.dataset.project);
        setSlide(flkty.selectedElement.dataset.slide);

        // Play selected video
        flkty.selectedElement.querySelector('video').play();
      });

      flkty.on('change', function () {
        flkty.cells.forEach(function (cell, i) {
          if (cell.element !== flkty.selectedElement) {
            // pause all other videos
            const video = cell.element.querySelector('video');
            if (video) {
              video.pause();
            }
            return;
          }
        });
      });

      // Fix wrapAround bug not showing cloned element
      flkty.select(1);
    }
  }, [flkty, videos]);

  const handleClick = orientation => {
    if (flkty !== undefined) {
      if (orientation === 'next') {
        flkty.next();
      } else if (orientation === 'prev') {
        flkty.previous();
      }
    }
  };

  return (
    <>
      <Container>
        <Title>{portfolio_title}</Title>
        <Flickity
          className={'carousel'}
          elementType={'div'}
          options={flktyOptions}
          flickityRef={e => setFlkty(e)}
        >
          {projects.map((project, i) => (
            <Slide key={i} data-project={project.name} data-slide={i + 1}>
              <a
                href={project.external_link.url}
                target="_blank"
                rel="noreferrer"
              >
                <video
                  ref={el => videos.current.push(el)}
                  src={project.video.url}
                  poster={project.video_poster.fluid.src}
                  preload="auto"
                  loop
                  muted
                  playsInline
                />
              </a>
            </Slide>
          ))}
        </Flickity>

        <Navigation>
          <button
            aria-label="Previous"
            className="previous"
            onClick={() => {
              handleClick('prev');
            }}
          >
            <Arrow color="var(--c-gray)" reverse />
          </button>

          <button
            aria-label="Next"
            className="next"
            onClick={() => {
              handleClick('next');
            }}
          >
            <Arrow color="var(--c-gray)" />
          </button>
        </Navigation>
      </Container>

      <Caption>
        <div className="project">{caption}</div>
        <p className="slide-count">{`${slide} of ${projects.length}`}</p>
      </Caption>
    </>
  );
};

export default Slideshow;
