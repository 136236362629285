import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import InnerHTML from 'dangerously-set-html-content';

import bp from '../assets/js/breakpoints';

import { Row, Col } from './grid-system';

const SubstackSignup = () => {
  const arrow1 = useRef(null);
  const arrow2 = useRef(null);

  useEffect(() => {
    const arrowTL = gsap.timeline({
      scrollTrigger: {
        trigger: arrow1.current,
        start: 'top bottom',
      },
    });

    arrowTL
      .from(arrow1.current, {
        drawSVG: 0,
        duration: 0.9,
        ease: 'power4.inOut',
      })
      .from(
        arrow2.current,
        {
          drawSVG: 0,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        '<+.7'
      );
  }, []);

  const substackScript = `
    <script>
      window.CustomSubstackWidget = {
        substackUrl: "jonathanjarvis.substack.com",
        placeholder: "Enter email address",
        buttonText: "Subscribe",
        theme: "custom",
        colors: {
          primary: "#87D7DE",
          input: "#14171C",
          email: "#F0E9DF",
          text: "#14171C",
        }
      };
    </script>
    <script src="https://substackapi.com/widget.js" async></script>
  `;

  return (
    <Row>
      <Col base={12} sm_md={10} sm_mdOffset={1}>
        <SubstackWrapper>
          <>
            <ArrowWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="203"
                height="242"
                viewBox="0 0 128.4 155.8"
                stroke="var(--c-purple)"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
              >
                <path
                  ref={arrow1}
                  d="M0.9,0.8c1.4,23.4,0.6,9.7-0.2,82.2c-0.7,62.4,89.6,67.7,121.6,63"
                />
                <path
                  ref={arrow2}
                  d="M111.3,155.1c0,0,10.5-8.4,15.6-8.3c5,0.1-16-7-15.9-10.8"
                />
              </svg>
            </ArrowWrapper>

            <h2>Get Our Newsletter</h2>

            <div id="custom-substack-embed" />

            <InnerHTML html={substackScript} />
          </>
        </SubstackWrapper>
      </Col>
    </Row>
  );
};

export default SubstackSignup;

/**
 * Styled Components
 *
 */
const SubstackWrapper = styled.section`
  position: relative;
  color: var(--color-black);
  padding: 7.2rem 0 9.6rem;

  @media (${bp.min.sm}) {
    display: flex;
    padding: 10.8rem 0 14.4rem;
    align-items: center;
  }

  h2 {
    font-size: clamp(3.2rem, 3vw, 5rem);
    line-height: 1;
    margin-bottom: var(--space-xs);

    @media (${bp.min.sm}) {
      margin-top: var(--space-xxs);
      margin-bottom: 0;
      width: 40%;
    }
  }

  #custom-substack-embed {
    flex: 1 0 auto;

    * {
      font-family: var(--f-sans);
    }

    .custom-substack-widget + .success {
      font-size: 1.2rem;
      line-height: 1.4;
      text-align: left;

      @media (${bp.min.sm}) {
        text-align: right;
      }
    }

    .custom-substack-widget button {
      max-width: 16rem;
    }
  }

  form {
    position: relative;
    max-width: 100%;

    @media (${bp.min.sm}) {
      margin-left: auto;
      width: 100%;
      max-width: 72rem;
    }
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: 16rem;
  transform: rotate(10deg);
  left: -23rem;
`;
