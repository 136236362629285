import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import FitText from '@kennethormandy/react-fittext';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const Container = styled.div`
  padding: var(--space-xxl) 0 10rem;

  @media (${bp.min.sm}) {
    padding: var(--space-xxl) 0 20rem;
  }
`;

const TaglineWrapper = styled.div`
  position: relative;
`;

const Scribble = styled.svg`
  position: absolute;
  top: -16rem;
  right: -16.3333%;
  width: 80%;

  path {
    visibility: hidden;
  }

  @media (${bp.min.sm}) {
    width: auto;
    top: -18rem;
    left: -16.3333%;
  }
`;

const Arrow = styled.div`
  position: absolute;
  bottom: -7rem;
  transform: rotate(90deg);
  left: 6%;

  path {
    visibility: hidden;
  }

  @media (${bp.min.sm_md}) {
    bottom: -12rem;
    left: initial;
    right: 65%;
    transform: none;
  }

  @media (${bp.min.md}) {
    right: 45%;
    transform: none;
  }

  @media (${bp.min.lg}) {
    right: 35%;
  }
`;

const Tagline = styled.h2`
  position: relative;
  line-height: 1.03;
  background-color: var(--c-white);
  color: var(--c-black);
  padding: var(--space-m) var(--space-m) var(--space-s);
  z-index: 10;

  @media (${bp.min.xs}) {
    padding: var(--space-l) var(--space-l) var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    padding: calc(var(--space-xl) + var(--space-xs)) var(--space-xl)
      var(--space-xl);
  }

  strong {
    font-variation-settings: 'wght' var(--f-bold);
  }
`;

const Content = styled.div`
  margin-top: var(--space-xxl);

  @media (${bp.min.sm}) {
    padding-right: 0;
  }

  h3 {
    font-size: 3.6rem;
    line-height: 4.4rem;
    letter-spacing: 0.05rem;
    max-width: 22ch;
    margin-bottom: var(--space-s);
    font-variation-settings: 'wght' var(--f-medium);

    @media (${bp.min.xs_sm}) {
      font-size: 5rem;
      line-height: 5.6rem;
    }

    strong {
      font-variation-settings: 'wght' var(--f-bold);
      color: var(--c-purple);
    }
  }

  ul {
    font-family: var(--f-sans);
    list-style-type: disc;
    margin: var(--space-xs) 0 var(--space-xs) 1em;

    li {
      font-size: 0.9em;
    }
  }

  p {
    padding-right: var(--space-xs);
    font-family: var(--f-sans);
    max-width: 40ch;

    strong {
      color: var(--c-blue);
    }

    & + p {
      margin-top: var(--space-m);
    }
  }
`;

const Overview = ({ data }) => {
  const { overview_title, overview_body } = data;

  const scribble = useRef(null);
  const arrow1 = useRef(null);
  const arrow2 = useRef(null);

  useEffect(() => {
    gsap.set([scribble.current, arrow1.current, arrow2.current], {
      visibility: 'visible',
    });

    gsap.from(scribble.current, {
      drawSVG: 0,
      duration: 2,
      delay: 1.5,
      ease: 'power3.inOut',
    });

    const arrowTL = gsap.timeline({
      scrollTrigger: {
        trigger: arrow1.current,
        start: 'top bottom',
      },
    });

    arrowTL
      .from(arrow1.current, {
        drawSVG: 0,
        duration: 0.9,
        ease: 'power4.inOut',
      })
      .from(
        arrow2.current,
        {
          drawSVG: 0,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        '<+.7'
      );
  }, []);

  return (
    <Container>
      <Row>
        <Col base={12} xs={10} sm_md={8} md={7} lg={6} mdOffset={1}>
          <TaglineWrapper>
            <Scribble width="432" height="376" viewBox="0 0 188.1 163.8">
              <path
                ref={scribble}
                fill="none"
                stroke="var(--c-purple)"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                d="M90.7,108.9c-1.9-19.7,2.9-9.4-10-52.8c-4.3-14.6-2.5-32.2,7.4-43.9c26.6-28.4,76.8,2.6,93.7,28.7
	c9.5,17.8,4.5,39.5-0.7,59c-4.9,23.7-19.2,38.5-43.5,28.1c-30.7-10.6-57.8-29.3-84.1-48.4c-8.6-6.2-20.9-12.5-28.8-5.4
	c-13.6,19.5-15.8,75,9.4,86.1c8.2,3.8,17.8,2.7,26.6,0.5c33.5-5.1,27.1-42.5,10-61.8c-7.1-8-16.9-14.4-20.4-24.6
	c-6.7-24.4,19.3-50.8,41.5-57.9c27.2-4.9,59.2,11.1,70.9,36c17.2,34.2-18.5,83.6-55.1,58.1c-26.7-15.9-45-42.4-66.8-64.6
	c-7.8-9.5-21.3-14.4-30.4-4.2c-13.4,13.8-11.6,40.8,2,54.2c21.9,13.8,51.8,8.9,75.9,3.9c14.8-2.8,11.3-22.1,15.1-33.2
	c1.2-16.2,21.5-7.4,30.5-3.3c29.5,22.3-18.3,44.9-38.7,32.3c-20.8-12.2,7.1-41.2,14.2-56c5.8-8-1.4-15.3-10.2-16.4
	C81.8,20.8,54.7,21.7,43.9,37c-2.1,6,2.8,12.1,7.8,16.1c16.9,13.7,26.8,16.4,48.4,15c0,0,76.3-29.7,3.9-29.7s-75.1,89.5-6.5,62.7
	s-10.9-52-10-14.6"
              />
            </Scribble>
            <Arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="203"
                height="242"
                viewBox="0 0 128.4 155.8"
                stroke="var(--c-purple)"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
              >
                <path
                  ref={arrow1}
                  d="M0.9,0.8c1.4,23.4,0.6,9.7-0.2,82.2c-0.7,62.4,89.6,67.7,121.6,63"
                />
                <path
                  ref={arrow2}
                  d="M111.3,155.1c0,0,10.5-8.4,15.6-8.3c5,0.1-16-7-15.9-10.8"
                />
              </svg>
            </Arrow>

            <Tagline>
              <FitText compressor={0.5} minFontSize={58} maxFontSize={150}>
                <div
                  dangerouslySetInnerHTML={{ __html: overview_title.html }}
                />
              </FitText>
            </Tagline>
          </TaglineWrapper>
        </Col>
      </Row>

      <Row>
        <Col base={12} sm_md={8} md={7} lg={6} sm_mdOffset={3} mdOffset={5}>
          <Content dangerouslySetInnerHTML={{ __html: overview_body.html }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
