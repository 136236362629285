import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Overview from '../components/overview';
import Slideshow from '../components/slideshow';
import SubstackSignup from '../components/substack-signup';

const IndexPage = ({ data }) => {
  const homepageData = data.allPrismicHomepage.edges[0].node.data;

  return (
    <Layout isHomepage>
      <SEO title="Home" />

      <Hero />
      <Overview data={homepageData} />
      <Slideshow data={homepageData} />
      <SubstackSignup />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          data {
            page_title
            overview_title {
              html
            }
            overview_body {
              html
            }
            portfolio_title
            projects {
              name
              external_link {
                url
              }
              video {
                url
              }
              video_source
              video_poster {
                fluid {
                  src
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`;
